import React from 'react'
import styled from 'styled-components'

import { Row, Col, FeatureCard } from '../Elements'
import HtmlContent from '../HtmlContent/HtmlContent'

const Content = styled(HtmlContent)`
  font-size: 0.9rem;
`
const ItemCol = styled(Col)`
  &&& {
    display: flex;
  }
`

export const ListItemViev = ({
  to,
  title,
  content,
  image,
  fade = true,
  after
}) => {
  return (
    <FeatureCard
      title={title}
      imageEl={image}
      link={to}
      hasFade={fade}
      footer={after}
    >
      <Content content={content} />
    </FeatureCard>
  )
}

export const ListWrapper = props => <Row {...props} />
export const ListWrapperItem = props => (
  <ItemCol col={{ xs: 1, sm: 1 / 2, md: 1 / 3 }} {...props} />
)
