import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from '@smooth-ui/core-sc'

const Container = styled(Box)`
  font-size: 1.2rem;
  & p {
    margin-bottom: 1rem;
  }
  & .wp-caption {
    max-width: 100%;
    width: auto !important;
  }
  & .size-full {
    width: 100%;
    height: auto;
  }
  & h2,
  & h3,
  & h4 {
    font-size: 1.2rem;
    font-weight: bold;
    clear: both;
    margin: 2rem 0 0.5rem;
  }
  & *:first-child {
    margin-top: 0;
  }
  & a {
    color: ${p => p.theme.colors.link};
    font-weight: bold;
  }
  & ul,
  & ol {
    margin: 0 0 1rem 2rem;
    & li {
      margin-bottom: 0.5rem;
    }
  }
  & .video-wrapper {
    display: block;
    position: relative;
    padding-top: 56.2%;
    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .button {
    background: ${p => p.theme.colors.primary};
    color: white;
    padding: 0.5rem 1rem;
    display: inline-block;
    border-radius: 9999px;
    margin: 1rem 1rem 1rem 0;
  }
`

const HtmlContent = ({ content, wrapIframe, ...rest }) => {
  const contentEnhanced = wrapIframe
    ? content
        .replace('<iframe', '<span class="video-wrapper"><iframe')
        .replace('</iframe>', '</iframe></span>')
    : content
  return (
    <Container
      {...rest}
      dangerouslySetInnerHTML={{ __html: contentEnhanced }}
    />
  )
}

HtmlContent.propTypes = {
  content: PropTypes.string
}

export default HtmlContent
