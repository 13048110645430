import React from 'react'
import { Box } from '@smooth-ui/core-sc'
import styled from 'styled-components'
import { PlayCircle } from 'styled-icons/fa-solid/PlayCircle'

const ImageInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(100%, 100%, 100%, 0.4);
`

export const Container = styled(Box)`
  position: relative;
  padding-top: 50%;
  background-image: url(${p => p.bg});
  background-size: cover;
`

const VideoImage = ({ image, withIcon = true, ...props }) => {
  return (
    <Container bg={image} {...props}>
      {withIcon && (
        <ImageInner>
          <PlayCircle size={40} />
        </ImageInner>
      )}
    </Container>
  )
}

export default VideoImage
