import React from 'react'
import { useLocation } from 'react-router-dom'

import { PageTitle } from 'components/Elements/index'
import BackLink from 'components/ListView/BackLink'

function useTitle(title) {
  const { state } = useLocation()
  const fallBackTitle = state && state.postTitle
  const usedTitle = title || fallBackTitle
  const titleView = (
    <>
      {state && state.fromUrl && (
        <BackLink from={state.fromUrl} name={state.fromName} />
      )}
      <PageTitle>{usedTitle}</PageTitle>
    </>
  )
  return {
    title: usedTitle,
    titleView
  }
}

export default useTitle
