import React from 'react'
import { gql } from 'apollo-boost'
import { Alert, Button } from '@smooth-ui/core-sc'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { fixTitle } from 'helpers/entities'
import PageWrapper from 'components/PageWrapper/PageWrapper'
import { P } from 'components/Elements/index'
import { LoadingSpinnerFullscreen } from 'components/Spinner/Spinner'
import HtmlContent from 'components/HtmlContent/HtmlContent'
import {
  ListItemViev,
  ListWrapper,
  ListWrapperItem
} from 'components/ListView/Elements'
import VideoImage from 'components/ListView/VideoImage'

import routeConfig from 'routeConfig'

import useTitle from 'helpers/useTitle'

const Page = ({
  match: {
    params: { slug },
    url
  },
  staticContext
}) => {
  const { data, loading } = useQuery(query, {
    variables: {
      slug
    }
  })
  // Handle not found in ssr
  if (!!staticContext && !loading && !data.pageBy) {
    staticContext.status = 404
  }
  const found = data && data.pageBy
  const { title, titleView } = useTitle(
    found ? fixTitle(found.title) : loading ? null : 'Side ikke funnet'
  )
  return (
    <PageWrapper title={title}>
      {titleView}
      {loading && !data && <LoadingSpinnerFullscreen />}
      {!loading && !found && (
        <Alert as="div">
          <>
            <P>
              Siden <strong>/{slug}</strong> ble ikke funnet.
            </P>
            <Button as={Link} to={routeConfig.home} mb={4}>
              Gå tilbake til forsiden
            </Button>
          </>
        </Alert>
      )}
      {found && (
        <>
          {!found.categoryList && found.content && (
            <HtmlContent content={found.content} wrapIframe />
          )}
          {found.navigationPage && found.navigationPage.menuItems && (
            <ListWrapper>
              {found.navigationPage.menuItems
                .filter(({ categorylink }) => {
                  return categorylink && categorylink.length && categorylink[0]
                })
                .map(({ text, image, categorylink, ...item }, i) => {
                  return (
                    <ListWrapperItem key={i}>
                      <ListItemViev
                        to={{
                          pathname: `${routeConfig.category}/${categorylink[0].slug}`,
                          state: {
                            fromName: title,
                            fromUrl: url,
                            postTitle: item.title
                          }
                        }}
                        content={`<p>${text}</p>`}
                        image={
                          image ? (
                            <VideoImage
                              image={image.sourceUrl}
                              withIcon={false}
                            />
                          ) : null
                        }
                        fade={false}
                        {...item}
                      />
                    </ListWrapperItem>
                  )
                })}
            </ListWrapper>
          )}
        </>
      )}
    </PageWrapper>
  )
}

const query = gql`
  query Page($slug: String!) {
    pageBy(uri: $slug) {
      title
      content
      navigationPage {
        menuItems {
          title
          text
          image {
            sourceUrl(size: LIST)
          }
          categorylink {
            __typename
            ... on Category {
              slug
            }
          }
        }
      }
    }
  }
`

export default Page
