import React from 'react'
import { Box, Button } from '@smooth-ui/core-sc'
import { Link } from 'react-router-dom'
import { ChevronLeft } from 'styled-icons/fa-solid/ChevronLeft'

const BackLink = ({ from, name }) => {
  return (
    <Box mb={1}>
      <Button as={Link} to={from} variant="light">
        <ChevronLeft size={16} />
        <Box display="inline-block" pl={1}>
          Tilbake til {name}
        </Box>
      </Button>
    </Box>
  )
}

export default BackLink
